<template>
  <UserPage
    :showHeader="showHeader"
    :showFooter="showFooter"
  />
</template>

<script>
import UserPage from '@/templates/user/AppUserPage'
import { mapGetters } from 'vuex'

export default {
  name: 'TheUserTop',
  components: {
    UserPage
  },
  data() {
    return {
      showHeader: false,
      showFooter: false,
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalHistory' ]
    ),
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
  },
  watch: {
    $route: async function(to, from) {
      this.showHeader = this.$router.history.current.meta.showHeader
      this.showFooter = this.$router.history.current.meta.showFooter
    },
  },
  mounted: async function () {
    this.showHeader = this.$router.history.current.meta.showHeader
    this.showFooter = this.$router.history.current.meta.showFooter

    // this.$store.dispatch('reset', initialState)
    // Disabled double tap zoom
    document.addEventListener(
      'dblclick',
      function(e){ e.preventDefault();}, { passive: false }
    )

    // swipe back 対策
    window.addEventListener("popstate", (e) => {
      // モーダルの開閉が関係しているか不明だが、戻ったページで数秒タッチが受け付けなくなる
      // 一瞬待つと操作がスムーズになるので、とりあえず以下の処理とする
      this.$store.dispatch('loadingMask/showLoadingMask')
      setTimeout(() => {
        const history = [...this.modalHistory]
        this.$store.dispatch('modal/setModalId', history.shift()) 
        this.$store.dispatch('modal/replaceModalHistory', history)
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }, 500)
    })

    // set initial body contents
    this.$store.dispatch('setBodyContentsName', 'map')

    await this.$nextTick()
    this.$_checkLastAccessDate()
  },
  methods: {
    $_checkLastAccessDate() {
      const lastAccessDate = this.$store.getters.lastAccessDate
      if (!lastAccessDate) {
        // 初回アクセス時はお知らせを全て既読にする
        const newsIds = this.$store.getters['news/userNews'].map(n => n.newsId)
        this.$store.dispatch('news/setCheckedNewsIds', newsIds)
      }
      this.$store.dispatch('set_last_access_date', new Date())
    },
  },
}
</script>
