<template>
  <div class="app-user-add-space-preview">
    <!-- Header -->
    <div class="header">
      <div class="title-text">イベント投稿 内容確認</div>
    </div>

    <!-- Body -->
    <div class="body">
      <div class="item-wrapper">
        <p class="item-title">追加したい情報の種類</p>
        <p class="item-value">{{ placeType }}</p>
      </div>

      <div
        v-for="(item, index) in items"
        :key="`${item.id}-item-${index}`"
      >
        <div v-if="item.id !== 'remarks'" class="item-wrapper">
          <p class="item-title">{{ item.title }}</p>
          <div v-if="Array.isArray(item.value)">
            <p
              v-for="(v, index) in item.value"
              :key="`review-item-${item.id}-${index}`"
              class="item-value"
              :class="index !== item.value.length - 1 && 'mb-0'"
            >
              {{ v }}
            </p>
          </div>
          <p v-else class="item-value">{{ item.value }}</p>
        </div>

        <div v-else class="item-wrapper mb-4">
          <p class="item-title">備考</p>
          <CommonTextarea
            v-if="item.value"
            :prefix="'add-space-remarks-preview'"
            :maxlength="'400'"
            :readonly="true"
            :remarks="item.value"
            :shown="shown"
            :userStyle="{
              fontSize: '14px',
              color: '#707070',
            }"
          />
        </div>
      </div>

      <hr />

      <div
        v-for="(comfirmation, index) in comfirmations"
        :key="`confirmation-${index}`"
        class="item-wrapper"
      >
        <p class="item-title">{{ comfirmation.title }}</p>
        <p class="item-value">同意する</p>
      </div>

      <div class="item-wrapper mb-4">
        <p class="item-title">
          お寄せいただいた情報については、運営者により修正や情報の追加をする場合があります。<br />
          また、掲載をお約束するものではございませんので、予めご了承ください。
        </p>
      </div>

      <div class="action-button">
        <WideButton
          :label="'上記の内容で送信する'"
          :backgroundColor="'#12B6D4'"
          :customStyle="{ padding: '16px' }"
          @click="$_saveEvent"
        />
        <WideButton
          :label="'入力画面に戻る'"
          :backgroundColor="'#707070'"
          :customStyle="{ padding: '16px' }"
          @click="$emit('hide-modal-click')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import WideButton from '@/components/atoms/common/AppWideButton'
import { getSummaryEventTerm } from '@/helper/common'
import inobounce from 'inobounce'
import moment from 'moment'

export default {
  name: 'AppUserAddEventPreview',
  components: {
    CommonTextarea,
    WideButton,
  },
  data() {
    return {
      shown: false,
      items: [],
      comfirmations: [
        { title: '利用規約の同意' },
        { title: 'プライバシーポリシーの同意' },
      ]
    }
  },
  props: {
    placeType: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
  },
  mounted: async function() {
    // enable pull down bounce
    inobounce.disable()

    this.items = Object.entries(this.value)
      .filter(this.$_displayValue)
      .map(([key, value]) => {
        return {
          id: key,
          title: this.$_getTitle(key),
          value: this.$_convertValue(key, value),
        }}
      )

    await this.$nextTick()
    await this.$nextTick()
    // CommonTextarea に表示されたタイミングを通知するため
    this.shown = true
  },
  methods: {
    $_convertValue(key, value) {
      if (key === 'heldDate') {
        return getSummaryEventTerm(value)
      } else if (key === 'termBeginDateYear' && value) {
        return moment(
          `${this.value.termBeginDateYear}/${this.value.termBeginDateMonth}/${this.value.termBeginDateDay}`
        ).format('YYYY年MM月DD日(ddd)')
      } else if (key === 'termEndDateYear' && value) {
        return moment(
          `${this.value.termEndDateYear}/${this.value.termEndDateMonth}/${this.value.termEndDateDay}`
        ).format('YYYY年MM月DD日(ddd)')
      } else if (key === 'endOfPublicationDateYear') {
        return moment(
          `${this.value.endOfPublicationDateYear}/${this.value.endOfPublicationDateMonth}/${this.value.endOfPublicationDateDay}`
        ).format('YYYY年MM月DD日(ddd)')
      } else {
        return value
      }
    },
    $_displayValue(value) {
      return value[0] && [
        'name',
        'spaceName',
        'zipcode',
        'prefecture',
        'area',
        'address',
        'heldDate',
        'termBeginDateYear',
        'termEndDateYear',
        'endOfPublicationDateYear',
        'applyPageUrl',
        'remarks',
        'companyName',
        'responsiblePersonName',
        'email',
        'tell'
      ].includes(value[0])
    },
    $_getTitle(key) {
      switch(key) {
        case 'name':
          return 'イベント名'
        case 'spaceName':
          return '開催地名'
        case 'zipcode':
          return '郵便番号'
        case 'prefecture':
          return '都道府県'
        case 'area':
          return '市区郡'
        case 'address':
          return 'それ以降'
        case 'applyPageUrl':
          return 'イベント情報掲載ページ'
        case 'heldDate':
          return '開催日'
        case 'termBeginDateYear':
          return '募集期間（開始）'
        case 'termEndDateYear':
          return '募集期間（終了）'
        case 'endOfPublicationDateYear':
          return '掲載終了日'
        case 'companyName':
          return '会社名（団体名）'
        case 'responsiblePersonName':
          return '担当者名'
        case 'email':
          return 'メールアドレス'
        case 'tell':
          return '電話番号'
        default:
          return ''
      }
    },
    async $_saveEvent() {
      this.$emit('post-event', { value: this.value })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-add-space-preview {
  width: 100%;
  height: auto;
  padding: 0;
  overflow-y: auto;
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 62px 20px 16px 20px;
    box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
    .title-text {
      color: #707070;
      font-size: 20px;
    }
  }
  .body {
    padding: 20px;
    .item-wrapper {
      .item-title {
        color: #A5A5A5;
        font-size: 12px;
        margin-bottom: 8px;
      }
      .item-value {
        color: #707070;
        font-size: 14px;
        margin-bottom: 32px;
      }
    }
    .back-button {
      width: 100%;
      font-size: 16px;
      width: 100%;
      min-height: 24px;
      padding: 1.5vh;
      margin-bottom: 0;
      border-radius: 5px;
      border: none;
      background-color: #707070;
      color: white;
    }
    .submit-button {
      width: 100%;
    }
    .action-button {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }
}
</style>