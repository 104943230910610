<template>
  <div class="app-user-header-sort-button">
    <Button
      :buttonStyle="buttonStyle"
      @on-button-click="() => $emit('on-footer-button-click')"
    >
      <template v-slot:buttonContents>
        <!-- ボタンアイコン -->
        <img :src="$_getIconSrc(button)" width="20px" height="20px" />
        <p class="button-text">{{ button.text }}</p>
      </template>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/atoms/user/AppUserButton'

export default {
  name: 'AppUserFooterButton',
  components: {
    Button
  },
  props: {
    button: {
      type: Object,
      default: () => ({
        id: null,
        text: '',
        active: false,
        inactiveIcon: '',
        activeIcon: ''
      })
    },
  },
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.button.active ? '#e2e6ea' : '#fff',
        border:this.button.active ? 'solid 1px #dae0e5' : 'none',
        color: '#0097b5',
        fontSize: '14px',
        width: '100%',
        height: '100%'
      }
    }
  },
  methods: {
    /**
     * フッターメニューアイコンのsrcを取得
     * @param {Object} button 対象メニューボタンオブジェクト
     */
    $_getIconSrc: function (button) {
      return this.$_isTargetButton(button.id)
        ? button.inactiveIcon
        : button.activeIcon
    },
    /**
     * フッターメニューボタンの状態管理
     * @param {String} target ボタンID
     */
    $_isTargetButton: function (target) {
      return this.$route.path.includes(target)
    },
  }
}
</script>

<style lang="scss" scoped>
.button-text {
  display: block;
  color: #0097b5;
  font-size: 0.75rem;
  margin-bottom: 0;
}
</style>
