<template>
  <div>
    <b-modal
      ref="add-place-modal"
      modal-class="common-modal"
      dialog-class="common-modal-dialog"
      content-class="review-modal-content"
      header-class="review-modal-header"
      body-class="common-modal-body"
      hide-header-close
      hide-footer
      hide-backdrop
      scrollable
      no-fade
    >
      <AddPlace
        v-bind="$props"
        @show-preview-click="(placeType, formValue) => {
          $_showPreviewModal(placeType, formValue)
        }"
        @go-back="() => {
          $store.dispatch('modal/setModalId', 'map')
          hideModal()
        }"
        @value-change="(value) => $_setValue(value)"
      />
      <template #modal-header>
        <Header
          @left-arrow-click="() => {
            $store.dispatch('modal/setModalId', 'map')
            hideModal()
          }"
          @logo-click="() => {
            $store.dispatch('modal/setModalId', 'map')
            hideModal()
          }"
        />
      </template>
    </b-modal>

    <AddPlacePreviewModal
      ref="add-place-preview"
      :placeType="placeType"
      :value="formValue"
      @go-back="() => {
        $store.dispatch('modal/setModalId', 'map')
        hideModal()
      }"
    />
  </div>
</template>
  
<script>
  import Header from '@/components/molecules/user/AppUserHeader'
  import AddPlace from '@/components/organisms/user/modals/contents/AppUserAddPlace'
  import AddPlacePreviewModal from '@/components/organisms/user/modals/AppUserAddPlacePreviewModal'

  export default {
    name: 'AppUserAddPlaceModal',
    components: {
      Header,
      AddPlace,
      AddPlacePreviewModal
    },
    props: {
      zipcode: {
        type: String,
        default: ''
      },
      prefecture: {
        type: String,
        default: ''
      },
      area: {
        type: String,
        default: ''
      },
      address: {
        type: String,
        default: ''
      },
      coordinates: {
        type: Object,
        default: () => ({ lat: 0, lng: 0 })
      },
    },
    data() {
      return {
        placeType: 'スペース',
        formValue: null,
      }
    },
    methods: {
      $_showPreviewModal(placeType, formValue) {
        this.placeType = placeType
        this.formValue = formValue
        this.$refs['add-place-preview'].showModal()
      },
      $_setValue(value) {
        console.log(value)
      },
      showModal() {
        this.$refs['add-place-modal'].show()
      },
      hideModal() {
        this.$refs['add-place-modal'].hide()
      }
    }
  }
</script>
  
<style lang="scss" scoped>
.review-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.review-modal-header {
  flex-direction: column;
  align-items: center;
}
</style>