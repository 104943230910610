<!-- organisms へ移動？-->
<template>
  <div
    class="map-ui-wrapper"
    v-on:touchstart="$_onTouchStart"
    v-on:touchmove="$_onTouchMove" 
    v-on:touchend="$_onTouchEnd" 
  >
    <Carousel
      v-bind:loop="true"
      v-bind:paginationEnabled="false"
      v-bind:perPage="1.1"
      v-bind:scrollPerPage="false"
      v-bind:navigateTo="navigateTo"
      v-on:pageChange="$_onPageChange"
      v-on:transition-end="$_onTransitionEnd"
    >
      <Slide
        v-for="item in carouselItems"
        v-bind:key="item.placeId"
        :data-id="item.placeId"
        @slideclick="$_onSlideClick"
      >
        <CarouselCard
          :placeId="item.placeId"
          :width="'100%'"
          :imageHeight="100"
          :imageWidth="136"
          :selectedId="currentPlace && currentPlace.placeId"
          @card-click="$_onClickCard"
        />
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import CarouselCard from '@/components/organisms/user/AppUserCarouselCard.vue'
import { mapGetters } from 'vuex'

// 上下スワイプの閾値
const SWIPE_RETIO = 30

export default {
  name: 'AppUserCarousel',
  components: {
    Carousel,
    Slide,
    CarouselCard,
  },
  data() {
    return {
      currentPlace: null,
      navigateTo: [],
      canMapMove: false
    }
  },
  props: {
    places: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    if (this.selectedMarker) this.$_setNavigateTo(this.selectedMarker.placeId)
  },
  watch: {
    selectedMarker() {
      if (this.selectedMarker) this.$_setNavigateTo(this.selectedMarker.placeId)
    },
    carouselItems() {
      // this.placesの変更によってカルーセルの内容が変わった際は、アニメーションは邪魔なので不要
      if (this.selectedMarker) this.$_setNavigateTo(this.selectedMarker.placeId, false)

      // 既存のカルーセルインデックスが、現在のプレイス配列（boudns内）の要素数を超えている場合は0に戻す
      if (
        (this.places.length && this.navigateTo.length) &&
        this.navigateTo[0] > this.places.length
      ) {
        this.$_setNavigateTo(this.places[0])
      }
    }
  },
  computed: {
    ...mapGetters(
      'map',
      [
        'mapCenter',
        'currentLocation',
        'selectedMarker'
      ]
    ),
    carouselItems: function () {
      // FIXME: 全てカルーセルに乗せるとプレイス数の増加とともにパフォーマンスが落ちる
      // 必要なプレイスだけ表示したい、並び順や選択状態の復元など考慮すること
      // 選択マーカーがthis.placesに含まれていない = bounds外にあるので、マーカーの選択を解除する
      // 本来はcomputedの中でやるべきではない
      if (
        !this.selectedMarker ||
        !this.places.find(p => p.placeId === this.selectedMarker.placeId)
      ) {
        this.$store.dispatch('map/setSelectedMarker', null)
      }

      return [...this.places]
    },
  },
  methods: {
    $_setNavigateTo(placeId, animation=true) {
      const index = this.carouselItems.findIndex(
        item => item.placeId === placeId
      )
      this.navigateTo = [Math.max(index, 0), animation]
    },
    $_onPageChange: function(index) {
      // このイベントじゃないと現在表示中のカードのインデックスが取れない
      this.currentPlace =  {...this.carouselItems[index]}
    },
    $_onTransitionEnd: function() {
      if (this.canMapMove && this.currentPlace) {
        this.$store.dispatch('map/setSelectedMarker', this.currentPlace)
      }
      // フラグリセット
      this.canMapMove = false
    },
    $_onSlideClick: function (dataSet) {
      const target = this.carouselItems.find(item => item.placeId === dataSet.id)
      // 上下スワイプでない場合をチェックしないとクリック処理と同時に実行されるため
      if (!this.swipeResult && target) {
        this.$emit('slider-click', target)
      }
    },
    /**
     * 上下スワイプとクリックを判定するため、タッチ開始時のpageYを保持
     */
    $_onTouchStart: function(e) {
      // 横スクロール時の画面の上下がたつき回避
      e.preventDefault()
      e.stopPropagation()

      this.startY = e.touches[0].pageY

      // スワイプ以外で発生したカルーセルの移動時に不要なリアクティブ処理がストアを介して発生するのを回避するため
      this.canMapMove = true
    },
    /**
     * 上下スワイプとクリックを判定するため、タッチ移動量をもとにスワイプの種類を保持
     */
    $_onTouchMove: function(e) {
      const touchMoveDistanceY = e.touches[0].pageY - this.startY
      if (touchMoveDistanceY > SWIPE_RETIO) {
        this.swipeResult = 'down'
      } else if (touchMoveDistanceY < -SWIPE_RETIO) {
        this.swipeResult = 'up'
      }
    },
    /**
     * スワイプフラグが上下スワイプの場合のみスワイプ処理を実行
     */
    $_onTouchEnd: function () {
      if (this.swipeResult) {
        this.$emit('vertical-swipe-end', this.swipeResult)
      }
      this.swipeResult = ''
    },
    $_onClickCard: function (target) {
      this.$emit('slider-click', target)
    }
  },
}
</script>

<style lang="scss" scoped>
.VueCarousel-slide {
  // HACKY:
  // 左右のスライドをちょい見せするためにずらす
  //（Vue-carouselの機能では実現できなかったため）
  // GPU使ったらちょいちょい落ちるのでやめる
  // will-change: transform;/* transfromに変更が加わることを示す */
  transform: translate(5%, 0px);
}
.map-ui-wrapper{
  // fit parent size (default)
  width: 100%;
  // GPU使ったらちょいちょい落ちるのでやめる
  // will-change: transform;/* transfromに変更が加わることを示す */
  transition: height 0.3s linear;
}
/* overwrite */
::v-deep(.VueCarousel-slide) {
  width: 100%;
}
</style>