var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-user-add-event-form"},[_vm._l((_vm.forms),function(form){return _c('div',{key:`${form.id}-input`,staticClass:"mt-4"},[(form.type === 'calender')?_c('div',[_c('div',{staticClass:"title-wrapper"},[_c('UserText',{attrs:{"size":form.size,"text":form.label}}),_c('p',{staticClass:"required-label"},[_vm._v("必須")])],1),_c('div',{staticClass:"event-held-date-calender-wrapper"},[_c('AppCalendar',{attrs:{"initialMonth":new Date(),"reservedDates":_vm.selectedDates},on:{"change-date-selection":(date) => {
            _vm.$_onChangeDateSelection(date, form.id)
          }}})],1)]):(form.type === 'date-selector')?_c('div',{staticClass:"date-selectors-wrapper"},[_c('div',{staticClass:"title-wrapper"},[_c('UserText',{attrs:{"size":form.size,"text":form.label}}),(form.required)?_c('p',{staticClass:"required-label"},[_vm._v("必須")]):_c('p',{staticClass:"optional-label"},[_vm._v("任意")])],1),_c('div',{staticClass:"description-wrapper"},_vm._l((form.description),function(d,index){return _c('p',{key:`${form.id}-description-${index}`,staticClass:"description"},[_vm._v(" "+_vm._s(d)+" ")])}),0),_c('div',{staticClass:"date-selectors"},[_c('div',{staticClass:"selector"},[_c('UserSelector',{attrs:{"idPrefix":'year-select',"options":_vm.yearOptions,"value":form.value.year,"width":'100px',"valueOffsetLeft":'24px'},on:{"select-chaged":(v) => _vm.$emit(
              'form-value-change',
              { id: `${form.id}Year`, value: v}
            )}}),_c('p',{staticClass:"label"},[_vm._v("年")])],1),_c('div',{staticClass:"selector"},[_c('UserSelector',{attrs:{"idPrefix":'month-select',"options":_vm.monthOptions,"value":form.value.month,"width":'80px',"valueOffsetLeft":'24px'},on:{"select-chaged":(v) => _vm.$emit(
              'form-value-change',
              { id: `${form.id}Month`, value: v}
            )}}),_c('p',{staticClass:"label"},[_vm._v("月")])],1),_c('div',{staticClass:"selector"},[_c('UserSelector',{attrs:{"idPrefix":'day-select',"options":_vm.dayOptions,"value":form.value.day,"width":'80px',"valueOffsetLeft":'24px'},on:{"select-chaged":(v) => _vm.$emit(
              'form-value-change',
              { id: `${form.id}Day`, value: v}
            )}}),_c('p',{staticClass:"label"},[_vm._v("日")])],1)])]):(form.type === 'select')?_c('UserSelectorForm',{attrs:{"idPrefix":form.idPrefix,"label":form.label,"size":form.size,"type":form.type,"value":form.value,"options":form.options,"required":form.required},on:{"select-chaged":(v) => _vm.$emit(
        'form-value-change',
        { id: form.id, value: v}
      )}}):_c('UserInputTextForm',{attrs:{"idPrefix":form.idPrefix,"label":form.label,"size":form.size,"type":form.type,"value":form.value,"placeHolder":form.value,"description":form.description,"remarks":form.remarks,"required":form.required,"pattern":form.pattern,"maxlength":form.maxlength},on:{"input-chaged":(v) => _vm.$emit(
        'form-value-change',
        { id: form.id, value: v}
      )}})],1)}),_c('div',{staticClass:"title-wrapper mt-4"},[_c('UserText',{attrs:{"size":'s',"text":'備考'}}),_c('p',{staticClass:"optional-label"},[_vm._v("任意")])],1),_c('p',{staticClass:"remarks-limitation-text"},[_vm._v(" 400字以内でご入力ください。（現在："),_c('span',{style:({ color: _vm.reachLimitation ? 'red' : '#707070' })},[_vm._v(_vm._s(_vm.trimedRemarksLength))]),_vm._v("文字） ")]),_c('CommonTextarea',{attrs:{"prefix":'user-remarks',"userStyle":{
      color: '#000',
      fontSize: '16px',
      border: '1px solid #A5A5A5',
      borderRadius: '4px',
      padding: '16px',
    },"rows":'7',"maxlength":String(_vm.maxRemarksLength)},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_vm._l((_vm.remarksNote),function(remark,index){return _c('p',{key:`remarks-note-line-${index}`,staticClass:"note-remarks"},[_vm._v(" "+_vm._s(remark)+" ")])}),_c('p',{staticClass:"information-provider-input-title"},[_vm._v("■情報ご提供者様のご連絡先をご入力ください。")]),_vm._m(0),_vm._l((_vm.additionalForms),function(form){return _c('div',{key:`${form.id}-input`,staticClass:"mt-4"},[_c('UserInputTextForm',{attrs:{"idPrefix":form.idPrefix,"label":form.label,"size":form.size,"type":form.type,"value":form.value,"placeHolder":form.value,"description":form.description,"remarks":form.remarks,"required":form.required},on:{"input-chaged":(v) => _vm.$emit(
        'form-value-change',
        { id: form.id, value: v}
      )}})],1)}),_c('hr'),_c('p',{staticClass:"description"},[_vm._v(" Poshspaceに掲載されるイベント告知用の画像のご用意がある場合はフォーム送信後に、poshmap01@gmail.com 宛に画像を添付の上、送信してください。 ")])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"description"},[_vm._v(" 以下ご入力いただく内容はPohspaceには掲載されません。"),_c('br'),_vm._v(" 掲載内容について確認がある場合は、ご入力いただいたメールアドレスまたは電話番号にご連絡させていただく場合がございます。 ")])
}]

export { render, staticRenderFns }