<template>
  <div class="app-user-add-event-form">
    <!-- Forms -->
    <div v-for="form in forms" :key="`${form.id}-input`" class="mt-4">
      <!-- Calendar -->
      <div v-if="form.type === 'calender'">
        <div class="title-wrapper">
          <UserText :size="form.size" :text="form.label" />
          <p class="required-label">必須</p>
        </div>
        <div class="event-held-date-calender-wrapper">
          <AppCalendar
            :initialMonth="new Date()"
            :reservedDates="selectedDates"
            @change-date-selection="(date) => {
              $_onChangeDateSelection(date, form.id)
            }"
          />
        </div>
      </div>

      <!-- Date Selectors -->
      <div
        v-else-if="form.type === 'date-selector'"
        class="date-selectors-wrapper"
      >
        <div class="title-wrapper">
          <UserText :size="form.size" :text="form.label" />
          <p v-if="form.required" class="required-label">必須</p>
          <p v-else class="optional-label">任意</p>
        </div>

        <div class="description-wrapper">
          <p
            v-for="(d, index) in form.description"
            :key="`${form.id}-description-${index}`"
            class="description"
          >
            {{ d }}
          </p>
        </div>
        <div class="date-selectors">
          <div class="selector">
            <UserSelector
              :idPrefix="'year-select'"
              :options="yearOptions"
              :value="form.value.year"
              :width="'100px'"
              :valueOffsetLeft="'24px'"
              @select-chaged="(v) => $emit(
                'form-value-change',
                { id: `${form.id}Year`, value: v}
              )"
            />
            <p class="label">年</p>
          </div>
          <div class="selector">
            <UserSelector
              :idPrefix="'month-select'"
              :options="monthOptions"
              :value="form.value.month"
              :width="'80px'"
              :valueOffsetLeft="'24px'"
              @select-chaged="(v) => $emit(
                'form-value-change',
                { id: `${form.id}Month`, value: v}
              )"
            />
            <p class="label">月</p>
          </div>
          <div class="selector">
            <UserSelector
              :idPrefix="'day-select'"
              :options="dayOptions"
              :value="form.value.day"
              :width="'80px'"
              :valueOffsetLeft="'24px'"
              @select-chaged="(v) => $emit(
                'form-value-change',
                { id: `${form.id}Day`, value: v}
              )"
            />
            <p class="label">日</p>
          </div>
        </div>
      </div>

      <!-- Single Selectors -->
      <UserSelectorForm
        v-else-if="form.type === 'select'"
        :idPrefix="form.idPrefix"
        :label="form.label"
        :size="form.size"
        :type="form.type"
        :value="form.value"
        :options="form.options"
        :required="form.required"
        @select-chaged="(v) => $emit(
          'form-value-change',
          { id: form.id, value: v}
        )"
      />

      <!-- Text Input -->
      <UserInputTextForm
        v-else
        :idPrefix="form.idPrefix"
        :label="form.label"
        :size="form.size"
        :type="form.type"
        :value="form.value"
        :placeHolder="form.value"
        :description="form.description"
        :remarks="form.remarks"
        :required="form.required"
        :pattern="form.pattern"
        :maxlength="form.maxlength"
        @input-chaged="(v) => $emit(
          'form-value-change',
          { id: form.id, value: v}
        )"
      />
    </div>

    <!-- Remark Textarea -->
    <div class="title-wrapper mt-4">
      <UserText :size="'s'" :text="'備考'" />
      <p class="optional-label">任意</p>
    </div>
    <p class="remarks-limitation-text">
      400字以内でご入力ください。（現在：<span :style="{ color: reachLimitation ? 'red' : '#707070' }">{{ trimedRemarksLength }}</span>文字）
    </p>
    <CommonTextarea
      v-model="remarks"
      :prefix="'user-remarks'"
      :userStyle="{
        color: '#000',
        fontSize: '16px',
        border: '1px solid #A5A5A5',
        borderRadius: '4px',
        padding: '16px',
      }"
      :rows="'7'"
      :maxlength="String(maxRemarksLength)"
    />
    <p
      v-for="(remark, index) in remarksNote"
      :key="`remarks-note-line-${index}`"
      class="note-remarks"
    >
      {{ remark }}
    </p>

    <p class="information-provider-input-title">■情報ご提供者様のご連絡先をご入力ください。</p>
    <p class="description">
      以下ご入力いただく内容はPohspaceには掲載されません。<br />
      掲載内容について確認がある場合は、ご入力いただいたメールアドレスまたは電話番号にご連絡させていただく場合がございます。
    </p>

    <!-- Additional Forms -->
    <div v-for="form in additionalForms" :key="`${form.id}-input`" class="mt-4">
      <UserInputTextForm
        :idPrefix="form.idPrefix"
        :label="form.label"
        :size="form.size"
        :type="form.type"
        :value="form.value"
        :placeHolder="form.value"
        :description="form.description"
        :remarks="form.remarks"
        :required="form.required"
        @input-chaged="(v) => $emit(
          'form-value-change',
          { id: form.id, value: v}
        )"
      />
    </div>

    <hr />

    <p class="description">
      Poshspaceに掲載されるイベント告知用の画像のご用意がある場合はフォーム送信後に、poshmap01@gmail.com 宛に画像を添付の上、送信してください。
    </p>
  </div>
</template>

<script>
import AppCalendar from '@/components/organisms/common/AppCommonCalendar'
import UserInputTextForm from '@/components/molecules/user/AppUserInputForm'
import UserSelectorForm from '@/components/molecules/user/AppUserSelectorForm'
import UserSelector from '@/components/atoms/user/AppUserSelector'
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import UserText from '@/components/atoms/user/AppUserText'
import inobounce from 'inobounce'
import { mapGetters } from 'vuex'
import prefectures from '@/js/prefectures'

const MAX_REVIEW_LENGTH = 400

export default {
  name: 'AppUserAddEventForm',
  components: {
    AppCalendar,
    UserInputTextForm,
    UserSelectorForm,
    UserSelector,
    CommonTextarea,
    UserText
  },
  props: {
    zipcode: {
      type: String,
      default: ''
    },
    prefecture: {
      type: String,
      default: ''
    },
    area: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      selectedDates: [],
      remarks: '',
      remarksNote: [
        '※スペースについてその他伝達事項があれば入力してください',
        '※出店希望者に公開する問い合わせ先（メールアドレス等）をご入力いただいた場合、運営側より確認の連絡をいたします。'
      ],
      confirmGuideline: false,
      confirmPrivacyPolicy: false,
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    yearOptions() {
      const currentYear = new Date().getFullYear()
      return [{
        label: '',
        options: [
          { value: '', text: '' },
          ...Array(3).fill().map((_, index) => ({
            value: (currentYear + index).toString(),
            text: (currentYear + index).toString(),
          }))
        ]
      }]
    },
    monthOptions() {
      return [{
        label: '',
        options: [
          { value: '', text: '' },
          ...Array(12).fill().map((_, index) => ({
            value: `${(index + 1).toString()}`,
            text: `${(index + 1).toString()}`,
          }))
        ] 
      }]
    },
    dayOptions() {
      return [{
        label: '',
        options: [
          { value: '', text: '' },
          ...Array(31).fill().map((_, index) => ({
            value: `${(index + 1).toString()}`,
            text: `${(index + 1).toString()}`,
          }))
        ]
      }]
    },
    forms() {
      return [
        {
          idPrefix: 'add-event-modal',
          id: 'name',
          label: 'イベント名',
          size: 's',
          type: 'text',
          value: '',
          placeHolder: '',
          maxlength: '50',
          description: '50字以内でご入力ください。',
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'spaceName',
          label: '開催地名',
          size: 's',
          type: 'text',
          value: '',
          placeHolder: '',
          maxlength: '50',
          description: '50字以内でご入力ください。',
          remarks: ['入力例）〇〇公園、〇〇スーパー店舗入口前、〇〇市〇〇1-2 交差点付近空きスペース'],
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'zipcode',
          label: '郵便番号（ハイフンあり）',
          size: 's',
          type: 'text',
          value: this.zipcode,
          placeHolder: 'ピンの座標から自動入力（編集可）',
          maxlength: '8',
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'prefecture',
          label: '都道府県',
          size: 's',
          type: 'select',
          value: this.prefecture,
          options: [ { value: null, text: '全都道府県'}, ...prefectures ],
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'area',
          label: '市区郡',
          size: 's',
          type: 'text',
          value: this.area,
          placeHolder: 'ピンの座標から自動入力（編集可）',
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'address',
          label: 'それ以降',
          size: 's',
          type: 'text',
          value: this.address,
          placeHolder: 'ピンの座標から自動入力（編集可）',
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'heldDate',
          label: '開催日',
          size: 's',
          type: 'calender',
          description: '複数日開催の場合は全日程を選択してください',
          value: [],
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'termBeginDate',
          label: '募集期間（開始）',
          size: 's',
          type: 'date-selector',
          description: ['出店者募集開始日を選択してください'],
          value: {
            year: '',
            month: '',
            day: ''
          },
        },
        {
          idPrefix: 'add-event-modal',
          id: 'termEndDate',
          label: '募集期間（終了）',
          size: 's',
          type: 'date-selector',
          description: ['出店者募集終了日を選択してください'],
          value: {
            year: '',
            month: '',
            day: ''
          },
        },
        {
          idPrefix: 'add-event-modal',
          id: 'endOfPublicationDate',
          label: '掲載終了日',
          size: 's',
          type: 'date-selector',
          description: [
            '・選択いただいた日までPoshspaceに掲載されます',
            '・基本的に出店者の募集終了日、またはイベント開催終了日と同日を選択してください'
          ],
          value: {
            year: '',
            month: '',
            day: ''
          },
          required: true
        },
        {
          idPrefix: 'add-event-modal',
          id: 'applyPageUrl',
          label: 'イベント情報掲載ページ（URL）',
          size: 's',
          type: 'text',
          value: '',
          remarks: [
            '※出店募集情報が載っているURLを入力してください',
            '※募集ページ（URL）がない場合、出店希望者に公開する問い合わせ先を次項目「備考」に入力してください。'
          ],
        },
      ]
    },
    additionalForms() {
      return [
        {
          idPrefix: 'add-event-modal',
          id: 'companyName',
          label: '会社名（団体名）',
          size: 's',
          type: 'text',
          value: '',
          placeHolder: '',
          description: '50字以内でご入力ください。',
          maxlength: '50',
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'responsiblePersonName',
          label: '担当者名',
          size: 's',
          type: 'text',
          value: '',
          description: '50字以内でご入力ください。',
          maxlength: '50',
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'email',
          label: 'メールアドレス',
          size: 's',
          type: 'text',
          value: '',
          required: true,
        },
        {
          idPrefix: 'add-event-modal',
          id: 'tell',
          label: '電話番号',
          size: 's',
          type: 'text',
          value: '',
          required: true,
        },
      ]
    },
    isWebkit() {
      return window.navigator.userAgent.includes('WebKit')
    },
    maxRemarksLength() {
      /**
       * 
       * Textareaの仕様で、value(入力したテキスト)の扱いがに以下の種類がある
       * 
       * *** 注：モバイルのWebkitのみの仕様 ***
       *
       *  - raw value        : ユーザーが入力したそのままの値
       *  - API value        : jsエンジンが触れる値(textarea.valueと同じ)で、改行コードはLFになってる
       *  - submission value : HTMLがフォームデータとして保持する値で改行コードはCR/LFになり、そのほかwrap属性も加味
       * 
       * つまり this.remarks の値は API value となり、改行コードがLFの1文字分である一方、Textarea自身が見ている値は
       * submission value となり、改行が CR/LF で2文字分のカウントとなる
       * 
       * この関数の処理では、exceptions はスペースと改行をすべて含む配列となり、exceptions.length を取ると改行の
       * カウントが１となる（要素数を撮っているだけなので、LF or CR/LF は気にしていない）
       * 
       * よってブラウザがWebkit系の場合のみ、改行を２文字としてカウントする（改行の数を抜き出してトータルに足す）
       * 
       * FIXME: 本来は AppTextare の責務であるため、本機能を AppTextarea に組み込むこと（AppUserObjectionにも重複する処理がある）
       * 
       */
      const exceptions = this.remarks.match(/[\s|\n|\r|\r\n]/g)
      if (exceptions) {
        if (this.isWebkit) {
          const lineBreaks = exceptions.filter(e => /\n/.test(e)).length
          return MAX_REVIEW_LENGTH + (exceptions ? exceptions.length + lineBreaks : 0)
        } else {
          // Gecko(Firefox)
          return MAX_REVIEW_LENGTH + (exceptions ? exceptions.length : 0)
        }
      } else {
        return MAX_REVIEW_LENGTH
      }
    },
    reachLimitation() {
      return this.trimedRemarksLength === MAX_REVIEW_LENGTH
    },
    trimedRemarksLength() {
      return this.remarks.replace(/[\s|\n|\r|\r\n]/g, '').length
    },
  },
  watch: {
    remarks() {
      this.$emit(
        'form-value-change',
        { id: 'remarks', value: this.remarks }
      )
    },
  },
  mounted: async function() {
    // enable pull down bounce
    inobounce.disable()
  },
  methods: {
    $_onChangeDateSelection: function (date, formId) {
      this.selectedDates = date
      this.$emit(
        'form-value-change',
        { id: formId, value: date }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-add-event-form {
  width: 100%;
  height: auto;
  padding: 0 1px;
  overflow-y: auto;
  .title-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 8px;
    .optional-label {
      color: #A5A5A5;
      margin-bottom: 0;
      font-size: 10px;
    }
  }
  .remarks-limitation-text {
    color: #707070;
    font-size: 12px;
    margin: 8px 0;
  }
  .description-wrapper {
    margin-bottom: 10px;
  }
  .event-held-date-calender-wrapper {
    width: 100%;
    height: 100%;
    margin: 16px 0;
    background-color: #fff;
    border: 1px solid #A5A5A5;
    border-radius: 4px;
    padding: 8px 0;

  }
  .description {
    color: #A5A5A5;
    margin-bottom: 0;
    font-size: 12px;
  }
  .date-selectors-wrapper {
    padding-bottom: 10px;
    .date-selectors {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .selector {
        display: flex;
        align-items: center;
        column-gap: 8px;
        .label {
          margin-bottom: 0;
        }
      }
    }
  }
  .required-label {
    color: red;
    margin-bottom: 0;
    font-size: 10px;
  }
  .information-provider-input-title {
    font-size: 14px;
    color: #707070;
    margin: 42px 0 16px 0;
  } 
  .note-remarks {
    color: #A5A5A5;
    margin-bottom: 0;
    font-size: 10px;
  }
}
</style>