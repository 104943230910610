<template>
  <div v-if="show" class="current-location-label">
    <div class="current-location-label-contents">
      <!-- Close button -->
      <b-button
        class="close-button"
        @click="() => $emit('hide')"
      >
        &#10005;
      </b-button>
      <!-- Text -->
      スペース・イベント投稿モードがオンになっています
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppUserAddPlaceLabel',
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>
.current-location-label-contents {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  font-weight: bold;
  font-size: calc((100vw - 16px - 16px - 8px - 16px) / 25);
  color: #fff;
  background-color: #FF9D00;
  padding: 8px 16px;
  .close-button {
    color: #FF9D00;
    background-color: #fff;
    border-color: #fff;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    font-size: 8px;
    padding: 0;
  }
}
</style>