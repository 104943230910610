<template>
  <div class="add-place-card-wrapper">
    <div class="card">
      <p class="card-text">
        この位置にスペースやイベント情報を投稿する
      </p>
      <div class="button-wrapper">
        <WideButton
          :label="'入力フォームはこちら'"
          :backgroundColor="'#FF9D00'"
          :customStyle="{ padding: '12px 16px'}"
          @click="$_onClickAddPlace"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'

export default {
  name: 'AppUserAddPlaceCard',
  components: { WideButton },
  methods: {
    $_onClickAddPlace() {
      this.$emit('click-button')
    }
  }
}
</script>

<style lang="scss" scoped>
.add-place-card-wrapper {
  position: relative;
  padding: 0 0.25rem;
  /* overwrite */
  .card {
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    .card-text {
      color: #707070;
      margin: 12px auto;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
        overflow: hidden;
      p {
        margin-bottom: 0;
        color: #707070;
        white-space: nowrap;
      }
    }
    .button-wrapper {
      width: 100%;
      padding: 0 16px 16px;
    }
  }
  /* overwrite */
  .card-body {
    height: var(--text-height);
    padding: 0.25rem 1.25rem;
  }
  /* overwrite */
  .box-shadow {
    border-radius: 16px;
    border: solid 1px #c6c6c6;
  }
}
</style>