<template>
  <input
    class="app-user-input-text"
    :type="type"
    :value="value"
    :placeholder="placeHolder"
    :maxlength="maxlength"
    :pattern="pattern"
    @input="$emit('on-text-chaged', $event)"
  />
</template>

<script>
export default {
  name: 'AppUserInputText',
  props: {
    type: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String,
      default: ''
    },
    maxlength: {
      type: String,
      default: ''
    },
    pattern: {
      type: String,
      default: ''
    },
  },
  methods: {
    $_change(v) {
      console.log(v)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-input-text {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #C6C6C6;
  border-radius: 4px;
}
</style>
