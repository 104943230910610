var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-user-add-space-form"},[_vm._l((_vm.forms),function(form){return _c('div',{key:`${form.id}-input`,staticClass:"mt-4"},[(form.type === 'select')?_c('UserSelector',{attrs:{"idPrefix":form.idPrefix,"label":form.label,"size":form.size,"type":form.type,"value":form.value,"options":form.options,"required":form.required},on:{"select-chaged":(v) => _vm.$emit(
        'form-value-change',
        { id: form.id, value: v}
      )}}):_c('UserInputText',{attrs:{"idPrefix":form.idPrefix,"label":form.label,"size":form.size,"type":form.type,"value":form.value,"placeHolder":form.value,"description":form.description,"remarks":form.remarks,"required":form.required,"pattern":form.pattern,"maxlength":form.maxlength},on:{"input-chaged":(v) => _vm.$emit(
        'form-value-change',
        { id: form.id, value: v}
      )}})],1)}),_c('div',{staticClass:"title-wrapper mt-4"},[_c('UserText',{attrs:{"size":'s',"text":'備考'}}),_c('p',{staticClass:"optional-label"},[_vm._v("任意")])],1),_c('p',{staticClass:"remarks-limitation-text"},[_vm._v(" 400字以内でご入力ください。（現在："),_c('span',{style:({ color: _vm.reachLimitation ? 'red' : '#707070' })},[_vm._v(_vm._s(_vm.trimedRemarksLength))]),_vm._v("文字） ")]),_c('CommonTextarea',{attrs:{"prefix":'user-remarks',"userStyle":{
      color: '#000',
      fontSize: '16px',
      border: '1px solid #A5A5A5',
      borderRadius: '4px',
      padding: '16px',
    },"rows":'7',"maxlength":String(_vm.maxRemarksLength)},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_vm._l((_vm.remarksNote),function(remark,index){return _c('p',{key:`remarks-note-line-${index}`,staticClass:"note-remarks"},[_vm._v(" "+_vm._s(remark)+" ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }