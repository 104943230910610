<template>
  <div class="add-place-button">
    <img
      v-if="on"
      width="54px"
      height="54px"
      :src="require('@/assets/image/add-place/add_place_button_on.png')"
      @click="() => $emit('click-button')"
    />
    <img
      v-else
      width="54px"
      height="54px"
      :src="require('@/assets/image/add-place/add_place_button_off.png')"
      @click="() => $emit('click-button')"
    />
  </div>
</template>

<script>

export default {
  name: 'AppUserAddPlaceButton',
  props: {
    on: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>