<template>
  <div class="app-user-input-form">
    <div class="title-wrapper">
      <UserText :size="size" :text="label" />
      <p v-if="required" class="required-label">必須</p>
    </div>

    <p v-if="description" class="description">{{ description }}</p>

    <UserSelector
      :idPrefix="idPrefix"
      :options="options"
      :value="value"
      @select-chaged="(value) => $emit('select-chaged', value)"
    />

    <p v-if="remarks" class="remarks">{{ remarks }}</p>
  </div>
</template>

<script>
import UserText from '@/components/atoms/user/AppUserText'
import UserSelector from '@/components/atoms/user/AppUserSelector'

export default {
  name: 'AppUserSelectorForm',
  components: {
    UserText,
    UserSelector
  },
  props: {
    idPrefix: {
      type: String,
      default: 'default'
    },
    label: {
      type: String,
      default: 'ラベル'
    },
    size: {
      type: String,
      default: 'm'
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    description: {
      type: String,
      default: ''
    },
    remarks: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.app-user-input-form {
  .title-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 8px;
  }
  .required-label {
    color: red;
    margin-bottom: 0;
    font-size: 10px;
  }
  .description {
    color: #A5A5A5;
    margin-bottom: 0;
    font-size: 12px;
  }
  .remarks {
    color: #A5A5A5;
    font-size: 10px;
  }
}
</style>
