<template>
  <div id="body">
    <Map />
  </div>
</template>

<script>
import Map from '@/components/organisms/user/AppUserMap'

/**
 * GoogleMapの再読み込みを避けるために、マップページをベースとして他コンテンツはモーダルで表示する
 */
export default {
  name: 'AppUserBody',
  components: {
    Map,
  },
}
</script>

<style lang="scss">
#body {
  height: 100%;
}
</style>
