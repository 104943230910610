<template>
  <p class="app-user-text" :style="{ fontSize: `${$_getFontSize()}px` }">
    {{ text }}
 </p>
</template>

<script>
export default {
  name: 'AppUserText',
  props: {
    text: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 's' // s, m, l, xl
    },
  },
  methods: {
    $_getFontSize() {
      switch(this.size) {
        case 's':
          return 12
        case 'm':
          return 16
        case 'l':
          return 18
        case 'xl':
          return 20
        default:
          return 16
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-text {
  color: #707070;
  margin-bottom: 0;
}
</style>
