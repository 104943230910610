<template>
  <!-- footer -->
  <div class="app-user-footer">
    <!-- メニューボタン -->
    <div class="bottom-menu" v-if="showMenuFooter">
      <AppUserFooterButton
        v-for="button in menuButtons"
        variant="light"
        class="footer-button"
        :key="button.id"
        :button="button"
        @on-footer-button-click="$_onMenuButtonClick(button.id)"
      />
    </div>
  </div>
</template>

<script>
import AppUserFooterButton from '@/components/molecules/user/AppUserFooterButton'
import { showRequireLoginPopup } from '@/helper/common'
import { FOOTER_MENU_BUTTONS } from '@/constants/user'
import { auth } from '@/firebase/config'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserFooter',
  components: {
    AppUserFooterButton,
  },
  data() {
    return {
      reservationNotice: 0,
      menuButtons: [...FOOTER_MENU_BUTTONS],
    }
  },
  computed: {
    ...mapGetters('modal', [ 'modalId' ]),
    showMenuFooter: function () {
      // ログイン画面追加したら、ログイン後のマップページでフッターのコンテンツが白抜けになった
      // リロードすると表示されるので、meta.showFooterの値をリアクティブに見れていない？
      // とりあえず常時true返す
      return true
      // return this.$router.history.current.meta.showFooter
    },
  },
  watch: {
    modalId() {
      this.menuButtons = [...FOOTER_MENU_BUTTONS].map(bt => ({
        ...bt,
        active: this.modalId === bt.id
      }))
    }
  },
  methods: {
    /**
     * フッターメニューボタンクリック時の処理
     * @param {String} id ボタンID（modalID）
     */
    $_onMenuButtonClick: function(id) {
      // ログインしていないユーザーのお気に入り動作制限
      if (
        id === 'likes' &&
        (!auth.currentUser || !auth.currentUser.emailVerified)
      ) {
        // customClassを定義すること
        // FIXME: customClassはscssで共通化する
        showRequireLoginPopup(
          'middle',
          {
            close: () => {},
            login: () => {
              // モーダルをとじる
              this.$store.dispatch('modal/setModalId', 'map')
              this.$router.push('/user/login')
            },
            signup: () => {
              // モーダルをとじる
              this.$store.dispatch('modal/setModalId', 'map')
              this.$router.push('/user/signup')
            },
          }
        )
      } else {
        // modalID更新前に履歴保存
        this.$store.dispatch('modal/setModalHistory', this.modalId)
        this.$store.dispatch('modal/setModalId', id)
      }
    },
    /**
     * マップページへ遷移
     */
    $_toTopButtonClick: function () {
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-user-footer {
  width: 100%;
  height: $footer-height;
  position: absolute;
  text-align: center;
  z-index: 1039; // バックドロップ（z-index: 1040）より後ろへ
  svg {
    color: #0097b5;
  }
}
.bottom-menu {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-button {
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    width: 25%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>

<style>
.custom-popup-container {
  margin-left: 8px;
  margin-right: 8px;
}
.custom-popup-deny-button {
  background-color: #fff !important;
  color: #12B6D4 !important;
  border: solid 1px #12B6D4 !important;
  width: 132px !important;
}
.custom-popup-confirm-button {
  background-color: #12B6D4 !important;
  color: #fff !important;
  border: solid 1px #fff !important;
  width: 132px !important;
}
.custom-popup-close-button {
  color: #12B6D4 !important;
  border-radius: 50% !important;
  border: solid 2px #12B6D4 !important;
  font-size: 24px !important;
  background-color: #fff !important;
  position: absolute;
  top: -22px;
  right: -18px;
  padding: 1.25rem;
}
.custom-popup-action {
  margin-top: 0;
}
</style>