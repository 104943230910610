<template>
  <div class="app-user-add-place">
    <!-- Header -->
    <div class="header-title">
      <div class="title-text">投稿入力フォーム</div>
    </div>

    <!-- Body -->
    <div class="add-place-body">
      <div class="title-wrapper">
        <UserText size="s" :text="'追加したい情報の種類を選択してください'" />
        <p class="required-label">必須</p>
      </div>

      <div class="radio-button-input-form">
        <input
          id="01-A"
          name="radio01"
          type="radio"
          value="スペース"
          v-model="placeType"
        >
        <label for="01-A" class="radio01">スペース</label>
        <p class="description">移動販売車両（キッチンカー等）が出店可能な部屋、空地その他のスペース等。</p>
        <input
          id="01-B"
          name="radio01"
          type="radio"
          value="イベント"
          v-model="placeType"
        >
        <label for="01-B" class="radio01">イベント</label>
        <p class="description">移動販売車両（キッチンカー等）が出店可能なイベント（祭り、催事等）。</p>
      </div>

      <UserAddSpace
        v-if="placeType === 'スペース'"
        v-bind="$props"
        @form-value-change="(data) => $_onChangeFormValue(data)"
      />
      <UserAddEvent
        v-else-if="placeType === 'イベント'"
        v-bind="$props"
        @form-value-change="(data) => $_onChangeFormValue(data)"
      />

      <div class="agreements-wrapper">
        <b-form-checkbox v-model="confirmGuideline">
          <p class="agreement-text">
            <span class="link-text" @click="$_onClickUserGuidelineLink">利用規約</span>に同意
          </p>
        </b-form-checkbox>
        <b-form-checkbox v-model="confirmPrivacyPolicy">
          <p class="agreement-text">
            <span class="link-text" @click="$_onClickPrivacyPolicyLink">プライバシーポリシー</span>に同意
          </p>
        </b-form-checkbox>
      </div>

      <div class="action-button">
        <WideButton
          :label="'入力内容を確認する'"
          :backgroundColor="filledForm && confirmed ? '#12B6D4' : '#707070'"
          :disabled="!filledForm || !confirmed"
          :customStyle="{ padding: '16px' }"
          @click="$emit('show-preview-click', placeType, $_getSortedValue())"
        />
        <WideButton
          :label="'マップ画面に戻る'"
          :backgroundColor="'#707070'"
          :customStyle="{ padding: '16px' }"
          @click="$emit('go-back')"
        />
      </div>
    </div>

    <!-- User guideline modal-->
    <b-modal
      ref="user-guideline-modal"
      scrollable
      hide-header
      body-class="overwrite-modal-body"
    >
      <GuidelineContents />
      <template #modal-footer="{ ok, cancel }">
        <div class="user-guideline-modal-footer">
          <b-button size="sm" variant="info" @click="() => {
            confirmGuideline = true
            ok()
          }">
            同意
          </b-button>
          <b-button size="sm" variant="dark" @click="() => {
            confirmGuideline = false
            cancel()
          }">
            キャンセル
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Privacy policy modal-->
    <b-modal
      ref="privacy-policy-modal"
      scrollable
      hide-header
      body-class="overwrite-modal-body"
    >
      <PrivacyPolicyContents />
      <template #modal-footer="{ ok, cancel }">
        <div class="privacy-policy-modal-footer">
          <b-button size="sm" variant="info" @click="() => {
            confirmPrivacyPolicy = true
            ok()
          }">
            同意
          </b-button>
          <b-button size="sm" variant="dark" @click="() => {
            confirmPrivacyPolicy = false
            cancel()
          }">
            キャンセル
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import UserText from '@/components/atoms/user/AppUserText'
import UserAddSpace from '@/components/organisms/user/AppUserAddSpaceForm'
import UserAddEvent from '@/components/organisms/user/AppUserAddEventForm'
import GuidelineContents from '@/components/organisms/user/AppUserUserGuidelineContents'
import PrivacyPolicyContents from '@/components/organisms/user/AppUserUserPrivacyPolicyContents'
import WideButton from '@/components/atoms/common/AppWideButton'
// import IconReview from '@/assets/image/icon_review.svg?component'
import inobounce from 'inobounce'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserAddPlace',
  components: {
    UserText,
    UserAddSpace,
    UserAddEvent,
    GuidelineContents,
    PrivacyPolicyContents,
    WideButton,
  },
  props: {
    zipcode: {
      type: String,
      default: ''
    },
    prefecture: {
      type: String,
      default: ''
    },
    area: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    coordinates: {
      type: Object,
      default: () => ({ lat: 0, lng: 0 })
    },
  },
  data() {
    return {
      placeType: 'スペース',
      confirmGuideline: false,
      confirmPrivacyPolicy: false,
      value: { // initial value
        zipcode: this.zipcode,
        prefecture: this.prefecture,
        area: this.area,
        address: this.address,
        coordinates: this.coordinates,
      },
      filledForm: false
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    confirmed() {
      return this.confirmGuideline && this.confirmPrivacyPolicy
    },
  },
  mounted: async function() {
    // enable pull down bounce
    inobounce.disable()
  },
  methods: {
    $_onChangeFormValue(data) {
      this.value[data.id] = data.value
      this.filledForm = this.$_checkFillForm()
    },
    $_checkFillForm() {
      return this.placeType === 'スペース'
        ? Boolean(
          this.value.address && 
          this.value.area && 
          this.value.coordinates.lat > 0 && 
          this.value.coordinates.lng > 0 && 
          this.value.prefecture && 
          this.value.name && 
          this.value.zipcode
        )
        : Boolean(
          this.value.address &&
          this.value.area && 
          this.value.coordinates.lat > 0 && 
          this.value.coordinates.lng > 0 && 
          this.value.prefecture &&
          this.value.name &&
          this.value.spaceName &&
          this.value.zipcode &&
          (this.value.heldDate && this.value.heldDate.length) &&
          (
            (
              this.value.termBeginDateDay &&
              this.value.termBeginDateMonth &&
              this.value.termBeginDateYear
            ) || (
              !this.value.termBeginDateDay &&
              !this.value.termBeginDateMonth &&
              !this.value.termBeginDateYear
            )
          ) &&
          ( 
            ( 
              this.value.termEndDateDay &&
              this.value.termEndDateMonth &&
              this.value.termEndDateYear
            ) || (
              !this.value.termEndDateDay &&
              !this.value.termEndDateMonth &&
              !this.value.termEndDateYear
            )
          ) &&
          (
            this.value.endOfPublicationDateDay &&
            this.value.endOfPublicationDateMonth &&
            this.value.endOfPublicationDateYear
          ) &&
          this.value.companyName &&
          this.value.email &&
          this.value.responsiblePersonName &&
          this.value.tell
        )
    },
    $_getSortedValue() {
      // プレビュー時のオーダーに並び替え
      return this.placeType === 'スペース'
        ? {
          name: this.value.name,
          zipcode: this.value.zipcode,
          prefecture: this.value.prefecture,
          area: this.value.area,
          address: this.value.address,
          coordinates: this.value.coordinates,
          applyPageUrl: this.value.applyPageUrl,
          remarks: this.value.remarks
        }
        : {
          name: this.value.name,
          spaceName: this.value.spaceName,
          zipcode: this.value.zipcode,
          prefecture: this.value.prefecture,
          area: this.value.area,
          address: this.value.address,
          coordinates: this.value.coordinates,
          heldDate: this.value.heldDate,
          termBeginDateYear: this.value.termBeginDateYear,
          termBeginDateMonth: this.value.termBeginDateMonth,
          termBeginDateDay: this.value.termBeginDateDay,
          termEndDateYear: this.value.termEndDateYear,
          termEndDateMonth: this.value.termEndDateMonth,
          termEndDateDay: this.value.termEndDateDay,
          endOfPublicationDateYear: this.value.endOfPublicationDateYear,
          endOfPublicationDateMonth: this.value.endOfPublicationDateMonth,
          endOfPublicationDateDay: this.value.endOfPublicationDateDay,
          applyPageUrl: this.value.applyPageUrl,
          remarks: this.value.remarks,
          companyName: this.value.companyName,
          responsiblePersonName: this.value.responsiblePersonName,
          email: this.value.email,
          tell: this.value.tell
        }
    },
    $_onClickUserGuidelineLink: async function () {
      // Sweetalert2のポップアップを検討したが、コンテンツにコンポーネントを
      // 指定する方法がうまくいかないので b-modal を使用
      this.$refs['user-guideline-modal'].show()
    },
    $_onClickPrivacyPolicyLink: async function () {
      // Sweetalert2のポップアップを検討したが、コンテンツにコンポーネントを
      // 指定する方法がうまくいかないので b-modal を使用
      this.$refs['privacy-policy-modal'].show()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-add-place {
  width: 100%;
  height: auto;
  padding: 0;
  overflow-y: auto;
  .header-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 62px 20px 16px 20px;
    box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
    .title-text {
      color: #707070;
      font-size: 20px;
    }
  }
  .add-place-body {
    padding: 20px;
    .title-wrapper {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
    .required-label {
      color: red;
      margin-bottom: 0;
      font-size: 10px;
    }
    .radio-button-input-form {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .description {
        color: #A5A5A5;
        margin: 8px 0 0 0;
        font-size: 12px;
      }
      /* overwrite */
      input[type=radio] {
        display: none;
      }
      .radio01 {
        color: #A5A5A5;
        user-select: none;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: auto;
        margin: 16px 0 0 0;
        padding-left: 32px;
      }
      .radio01::before {
        background: #fff;
        border: 1px solid #A5A5A5;
        border-radius: 50%;
        content: '';
        display: block;
        height: 20px;
        left: 5px;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        width: 20px;
      }
      .radio01::after {
        background: #12B6D4;
        border-radius: 50%;
        content: '';
        display: block;
        height: 10px;
        left: 10px;
        margin-top: -5px;
        opacity: 0;
        position: absolute;
        top: 48%;
        width: 10px;
      }
      input[type=radio]:checked + .radio01::after {
        opacity: 1;
      }
    }
    .agreements-wrapper {
      color: #A5A5A5;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      margin: 32px 0;
      .agreement-text {
        font-size: 16px;
        margin-bottom: 0;
      }
      .link-text {
        color: #12B6D4;
        text-decoration: underline;
        line-height: 24px;
      }
    }
    .action-button {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }
}

/* user guideline and privacy policy modal */
.overwrite-modal-body {
  color: black;
  .app-user-user-guideline-contents {
    .user-guideline-title {
      padding: 12px 20px 0 20px;
      box-shadow: none;
    }
  }
  .app-user-privacy-policy-contents {
    .privacy-policy-title {
      padding: 12px 20px 0 20px;
      box-shadow: none;
    }
  }
}
.user-guideline-modal-footer {
  width: 100%;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  button {
    width: 100px;
  }
}
.privacy-policy-modal-footer {
  width: 100%;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  button {
    width: 100px;
  }
}
</style>