<template>
  <div class="app-user-page">
    <div
      v-if="showHeader"
      class="header-wrapper"
    >
      <Header />
    </div>

    <div class="body-wrapper">
      <Body />
      <LoadingMask v-show="showBodyLoadingMask" />
    </div>

    <div
      v-if="showFooter"
      class="footer-wrapper"
    >
      <Footer />
    </div>

    <LoadingMask />
  </div>
</template>

<script>
/**
 * Templateなので、見た目に関わる実装のみとする
 */
// Common
import LoadingMask from '@/components/molecules/common/AppFullscreenLoadingMask'
// User
import Header from '@/components/organisms/user/AppUserHeader'
import Body from '@/components/organisms/user/AppUserBody'
import Footer from '@/components/organisms/user/AppUserFooter'

export default {
  name: 'AppUserPage',
  components: {
    Header,
    Body,
    Footer,
    LoadingMask,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sliderIndex: 0,
      pagenationIndex: 0
    }
  },
  computed: {
    showBodyLoadingMask: function () {
      return this.$store.getters.showBodyLoadingMask
    },
  },
  mounted() {
    // Set Height in mobile
    this.$_setDisplaySizeHandler()
  },
  methods: {
    /**
     * モバイルのlandscape mode対応
     */ 
    $_setDisplaySizeHandler: function () {
      // 画面のサイズ変動があった時に高さを再計算する
      window.addEventListener('resize', this.$_setFillHeight)
      // 初期化
      this.$_setFillHeight()
      // 画面の縦横が入れ替わった際にサイズを再計算
      window.onorientationchange = function () {
        switch ( window.orientation ) {
          case 0:
            break;
          case 90:
            this.$_setFillHeight()
            break;
          case -90:
            this.$_setFillHeight()
            break;
        }
      }
    },
    /**
     *  SP ブラウザのアドレスバー、ボトムUIの高さを考慮した描画領域の高さを設定
     */
    $_setFillHeight() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
}
</script>

<style lang="scss">
/**
 * User (SP)
 */
.app-user-page {
  /* font manager */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // disable double tap zoom
  touch-action: manipulation;

  /* responsive for full height */
  height: 100vh; /* Fallback */
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-wrapper {
    height: $header-height;
  }
  .footer-wrapper {
    height: $footer-height;
  }
}
svg {
  transform:translate(0,0);
}
// No result toast at keyword search
.display-flex {
  display: flex !important;
  border-radius: 16px;
}
.container-offset {
  top: 20% !important;
}
</style>
