<template>
  <div class="map-area">
    <MapContents
      v-bind="mapConfig"
      v-bind:places="displayPlaces"
      v-bind:showUiLayer="true"
      v-bind:enabledCluster="true"
      v-bind:verticalSwipeDirection="verticalSwipeDirection"
      v-bind:waitingCurrentLocation="waitingCurrentLocation"
      v-on:map-loadend="$_onMapLoadEnd"
      v-on:vertical-direction-change="$_onVerticalSwipe"
    >
      <!-- Top UI -->
      <template v-slot:topUi>
        <div v-if="mode === 'add-place'">
          <AddPlaceLabel 
            v-bind:show="showAddPlaceLabel"
            v-on:hide="() => { showAddPlaceLabel = false }"
          />
        </div>
        <div v-else-if="mode === 'map'">
          <CurrentLocationLabel
            v-bind:show="showCurrentLocationLabel"
            v-bind:waitingCurrentLocation="waitingCurrentLocation"
            v-on:hide="() => { showCurrentLocationLabel = false }"
          />
          <PlaceTypeSwitches />
        </div>
      </template>

      <!-- Middle UI -->
      <template v-slot:middleUi>
        <AddPlaceButton
          :on="mode === 'add-place'"
          @click-button="$_onClickAddPlaceButton"
        />
        <CurrentLocationButton
          :waitingCurrentLocation="waitingCurrentLocation"
          @click-button="$_onClickCurrentLocationButton"
        />
      </template>

      <!-- Bottom UI -->
      <template v-if="showBottomUi || showMarker" v-slot:bottomUi="{ places }">
        <AppAddPlaceCard v-if="showMarker"  @click-button="$_showAddPlaceModal" />
        <AppCarousel
          v-else
          v-bind:places="places"
          v-on:vertical-swipe-end="$_onVerticalSwipe"
          v-on:slider-click="$_onSliderClick"
        />
      </template>
    </MapContents>

    <!-- add place modal-->
    <AddPlaceModal
      ref="add-place-modal"
      v-bind="addPlace"
    />
  </div>
</template>

<script>
import MapContents from '@/components/molecules/user/AppUserMapContents'
import AddPlaceButton from '@/components/molecules/user/AppUserAddPlaceButton.vue'
import CurrentLocationButton from '@/components/molecules/user/AppUserCurrentLocationButton.vue'
import CurrentLocationLabel from '@/components/molecules/user/AppUserCurrentLocationLabel.vue'
import AddPlaceLabel from '@/components/molecules/user/AppUserAddPlaceLabel.vue'
import PlaceTypeSwitches from '@/components/molecules/user/AppUserPlaceTypeSwitches.vue'
import AppCarousel from '@/components/organisms/user/AppUserCarousel.vue'
import AppAddPlaceCard from '@/components/organisms/user/AppUserAddPlaceCard.vue'
import AddPlaceModal from '@/components/organisms/user/modals/AppUserAddPlaceModal'
import { getOpenNews } from '@/helper/firestore/news'
import {
  getDistanceFromCurrentLocation,
  isInBounds,
  distanceSort
} from '@/helper/user'
import { getFilterCallBacks } from '@/helper/place'
import { showInfoPopupAlert, showTopPopup } from '@/helper/common'
import { DEFAULT_POSITION, DEFAULT_ZOOM, CULSTER_ZOOM_LEVEL } from '@/constants/user'
import inobounce from 'inobounce'
import { mapGetters } from 'vuex'

export default {
  name: 'Map',
  components: {
    MapContents,
    AddPlaceButton,
    CurrentLocationButton,
    AddPlaceLabel,
    AppCarousel,
    CurrentLocationLabel,
    PlaceTypeSwitches,
    AddPlaceModal,
    AppAddPlaceCard
  },
  data() {
    return {
      map: null,
      google: null,
      conditionalPlaces: [],
      verticalSwipeDirection: 'down',
      watchPositionId: null,
      waitingCurrentLocation: false,
      showCurrentLocationLabel: false,
      showAddPlaceLabel: false,
      mode: 'map',
      marker: null,
      showMarker: false,
      eventListener: null,
      confirmPolicy: false,
      geocoder: null,
      addPlace: {
        zipcode: '',
        prefecture: '',
        area: '',
        address: '',
        coordinates: { lat: 0, lng: 0},
      }
    }
  },
  computed: {
    ...mapGetters(
      'map',
      [
        'mapConfig',
        'mapCenter',
        'mapZoom',
        'mapBounds',
        'currentLocation',
        'basePoint'
      ]
    ),
    ...mapGetters(
      'place',
      [
        'validSpaces',
        'validEvents',
      ]
    ),
    ...mapGetters(
      'filter',
      [
        'hasCondition',
        'conditions',
        'prevConditions'
      ]
    ),
    ...mapGetters('modal', [ 'modalId' ]),
    targetTypePlaces() {
      if (this.conditions.showSpace && !this.conditions.showEvent) {
        // only space
        return [...this.validSpaces]
      } else if (this.conditions.showEvent && !this.conditions.showSpace) {
        // only event
        return [...this.validEvents]
      } else if (this.conditions.showSpace && this.conditions.showEvent) {
        // both
        return [
          ...this.validSpaces,
          ...this.validEvents
        ]
      } else {
        // none
        return []
      }
    },
    isTypeChanged() {
      return (
        this.conditions.showSpace !== this.prevConditions.showSpace ||
        this.conditions.showEvent !== this.prevConditions.showEvent
      )
    },
    showBottomUi: function () {
      return this.mapZoom >= CULSTER_ZOOM_LEVEL
    },
    displayPlaces() {
      return this.hasCondition ? [...this.conditionalPlaces] : [...this.targetTypePlaces]
    },
  },
  watch: {
    conditions: {
      handler() {
        this.$_filterPlaceByConditions()
      },
      deep: true
    },
    currentLocation() {
      this.showCurrentLocationLabel = !this.currentLocation
    }
  },
  async created() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    try {
      // フィルタリング条件を初期化
      this.$store.dispatch('filter/resetState')

      // 距離ソートの基準点取得
      if (
        !this.basePoint ||
        (!this.basePoint.lat || !this.basePoint.lng) ||
        (
          !Math.floor(this.basePoint.lat) ||
          !Math.floor(this.basePoint.lng)
        ) // lat: 0.000..., lng: 0.000...があり得る
      ) this.$_setBasePoint()

      // Load chunk data (do not wait)
      await this.$store.dispatch('place/setChunks')
    } catch (e) {
      alert(e)
    } finally {
      this.$store.dispatch('loadingMask/hideLoadingMask')
    }
  },
  mounted: async function() {
    try {
      // this.$store.dispatch('reset')

      // Disable pull down bounce
      inobounce.enable()

      // Set loading mask
      this.$store.dispatch('loadingMask/showLoadingMask')

      // Set News(needless to await)
      this.$_setNews()

      this.showCurrentLocationLabel = !this.currentLocation

      // 運営会社、利用規約、プライバシーポリシー表示
      await this.$nextTick()
      if (this.$route.query.openModalId) {
        this.$store.dispatch('modal/setModalId', this.$route.query.openModalId)
      } 
    } catch(error) {
      console.log(error)
    } finally {
      this.$store.dispatch('loadingMask/hideLoadingMask')
    }
  },
  methods: {
    $_onClickAddPlaceButton() {
      if (this.showMarker) {
        // OFF
        this.mode = 'map'
        this.showAddPlaceLabel = false
        this.verticalSwipeDirection = 'down'
        if (this.marker) this.marker.setMap(null)
        this.google.maps.event.removeListener(this.eventListener)
      } else {
        // ON
        this.mode = 'add-place'
        this.showAddPlaceLabel = true
        this.verticalSwipeDirection = 'up'
        // マーカー作成
        this.marker = new this.google.maps.Marker({
          icon: {
            url: require('@/assets/image/add-place/location_target.svg')
          },
          position: this.map.getCenter(),
          map: this.map
        })
        this.eventListener = this.google.maps.event.addListener(
          this.map,
          'center_changed',
          () => { this.marker.setPosition(this.map.getCenter()) })
        // 説明モーダル表示（初回のみ）
        if (!this.$store.getters['user/showedAddPlaceModal']) {
          this.$store.dispatch('modal/setModalId', 'add-place-introduction-modal')
          this.$store.dispatch('user/setShowedAddPlaceModal', true)
        }
      }
      this.showMarker = !this.showMarker
    },
    $_onClickUserPolicyLink: async function () {
      // Sweetalert2のポップアップを検討したが、コンテンツにコンポーネントを
      // 指定する方法がうまくいかないので b-modal を使用
      this.$refs['user-guideline-modal'].show()
    },
    $_showAddPlaceModal() {
      this.$store.dispatch('loadingMask/showLoadingMask')

      if (!this.geocoder) {
        this.geocoder = new this.google.maps.Geocoder()
      }

      const latlng = {
        lat: parseFloat(this.map.getCenter().lat()),
        lng: parseFloat(this.map.getCenter().lng()),
      }

      try {
        this.geocoder
          .geocode(
            { location: latlng },
            (result, status) => {
              if (status === 'OK' && result[0]) {
                for (let component of result[0].address_components) {
                  if (component.types.includes('postal_code')) {
                    this.addPlace.zipcode = component.long_name;
                  } else if (component.types.includes('administrative_area_level_1')) {
                    this.addPlace.prefecture = component.long_name;
                  } else if (component.types.includes('locality')) {
                    this.addPlace.area = component.long_name;
                  }
                }
                this.addPlace.address = result[0].formatted_address
                  .split(this.addPlace.area)[1]
                this.addPlace.coordinates = latlng

                this.$refs['add-place-modal'].showModal()
              } else {
                alert("No results found");
              }
            },
            (error) => { console.log(error) }
          )
      } catch(error) {
        alert(error)
      }

      this.$store.dispatch('loadingMask/hideLoadingMask')
    },
    $_onClickCurrentLocationButton() {
      if (this.map && this.currentLocation) {
        // 現在位置へ移動
        this.map.setCenter({
          lat: this.currentLocation.lat,
          lng: this.currentLocation.lng,
        })
      }
      // 現在位置を要求
      this.$_watchCurrentLocation(true)
    },
    $_isInBounds: function (place) {
      // coordinnates, boundsがなければtrue
      // FIXME:
      // 登録時に何らかの原因で座標が登録されないことがあるので、登録・更新時に
      // エラーを出して弾くこと
      return (
        !place.coords ||
        // !place.coordinates ||
        !this.mapBounds ||
        isInBounds(place, this.mapBounds)
      )
    },
    $_setBasePoint() {
      // Has current location ?
      if (this.currentLocation) {
        this.$store.dispatch('map/setBasePoint', this.currentLocation)
      } else {
        // IPから取得はメリットとコストが釣り合わないので廃止
        this.$store.dispatch('map/setBasePoint', DEFAULT_POSITION)
      }
    },
    $_distanceSort(a, b) {
      if (!a.coords || !b.coords) return 0
      const from = this.currentLocation || DEFAULT_POSITION
      const distA = getDistanceFromCurrentLocation(from, a)
      const distB = getDistanceFromCurrentLocation(from, b)
      return distA - distB
    },
    $_setNews: async function () {
      this.$store.dispatch('news/setUserNews', await getOpenNews())
    },
    $_watchCurrentLocation: async function (panTo=false) {
      this.waitingCurrentLocation = true

      if (!navigator || !navigator.geolocation) {
        // Geolocation API非対応
        alert("この端末では位置情報が取得できません")
        this.waitingCurrentLocation = false
        return
      }

      // 以前のWatchIDをクリア（意味があるかは不明）
      if (this.watchPositionId) {
        navigator.geolocation.clearWatch(this.watchPositionId)
      }

      // Geolocation API対応
      this.watchPositionId = navigator.geolocation.watchPosition(
        (position) => this.$_setCurrentLocation(position, panTo), // success
        (error) => this.$_showErrorCurrentLocation(error),        // failed
        {
          enableHighAccuracy: false , // そんなに正確じゃなくていい
          maximumAge: 30000 , // そんなに頻繁に更新しなくていいので30秒
          "timeout": 30000 ,
        }
      )
    },
    $_setCurrentLocation: async function (position) {
      try {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }

        // 初回取得時のみ自動的にパン
        if (!this.currentLocation) {
          this.map.setCenter(location)
          // 選択済みプレイスをクリアしてカルーセルを先頭にリセットする
          this.$store.dispatch('place/setSelectedPlace', null)
        }

        this.$store.dispatch('map/setCurrentLocation', location)

      } catch (error) {
        alert(`現在位置の更新に失敗しました。${error}`)
      }
      this.waitingCurrentLocation = false
    },
    $_showErrorCurrentLocation: function (error) {
      // エラーメッセージを表示
      switch(error.code) {
        case 1: { // PERMISSION_DENIED
          this.$store.dispatch('modal/setModalId', 'failed-get-location')
          break
        }
        case 2: // POSITION_UNAVAILABLE
          alert("現在位置が取得できませんでした")
          break
        case 3: // TIMEOUT
          alert("タイムアウトになりました")
          break
        default:
          console.log("その他のエラー(エラーコード:"+error.code+")")
          break
      }
      this.waitingCurrentLocation = false
      this.$store.dispatch('loadingMask/hideLoadingMask')
    },
    $_onVerticalSwipe: function (direction) {
      // プレイス追加モードの場合はスキップ
      if (this.showMarker) return

      this.verticalSwipeDirection = direction
    },
    $_onSliderClick: async function (target) {
      this.$store.dispatch('place/setSelectedPlace', target.placeId)
      // modalID更新前に履歴保存
      this.$store.dispatch('modal/setModalHistory', this.modalId)
      this.$store.dispatch('modal/setModalId', `${target.type}-detail`)
    },
    $_fitBounds(places) {
      if (!this.map || !this.google) return

      const bounds = new this.google.maps.LatLngBounds()
      for (let i = 0; i < places.length; i++) {
        const coords = places[i].coords || places[i].coordinates
        bounds.extend(new this.google.maps.LatLng(coords.lat, coords.lng))
      }
      this.map.fitBounds(bounds)
    },
    $_filterPlaceByConditions: function () {
      try {
        let newPlaces
        // 各プレイス毎にタイプ判定するのは冗長なので対象タイプのみフィルタにかける
        if (!this.targetTypePlaces.length) {
          // 対象タイププレイスなし -> プレイスなし
          newPlaces = []
        } else {
          const callbacks = getFilterCallBacks(this.conditions)
          newPlaces = [...this.targetTypePlaces]
          for (let i = 0; i < callbacks.length; i++) {
            newPlaces = newPlaces.filter(callbacks[i])
          }
        }

        if (!newPlaces.length) {
          showTopPopup('<p style="font-size: 12px; margin-bottom: 0;">検索したキーワードに<br />一致する情報が見つかりませんでした</p>')
        } else if (!this.isTypeChanged){ // タイプ切り替え時は bounds 移動しない
          // fit bounds to conditional places
          this.$_fitBounds(newPlaces)
        }

        // 距離順にソートして設定
        this.conditionalPlaces = newPlaces.sort((a, b) => distanceSort(a, b))
      } catch (error) {
        showInfoPopupAlert(
          `スペース/イベントの読み込みに失敗しました。<br /><br />${error.message || ''}`,
        )
        return
      }
    },
    $_onMapLoadEnd: function ({ map, google }) {
      this.map = map
      this.google = google

      // 少し待ってからマップを移動させないとピンが表示されない？
      setTimeout(() => {
        try {
          // Fitting bounds
          this.map.setZoom(this.mapZoom || DEFAULT_ZOOM)
          const center = this.mapCenter || this.basePoint || DEFAULT_POSITION
          this.map.panTo({
            lat: center.lat,
            lng: center.lng,
          })
          // query string による表示プレイスタイプ指定を反映
          if (this.$route.query.placeType) {
            this.$store.dispatch(
              'filter/setConditions',
              {
                  showSpace: this.$route.query.placeType.includes('space'),
                  showEvent: this.$route.query.placeType.includes('event')
              }
            )
          } 
        } catch(error) {
          console.log(error)
        } finally {
          this.$store.dispatch('loadingMask/hideLoadingMask')
        }
      }, 3000)
    },
  },
}
</script>

<style lang="scss" scoped>
.map-area {
  // control map size
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
  .add-place-action-card {
    background-color: white;
    margin: 0 16px;
    height: 100px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .user-guideline-text {
      color: #12B6D4;
      text-decoration: underline;
    }
  }
}
::v-deep(.modal-dialog) {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
  max-height: 100% !important;
  .modal-content {
    border: none;
    border-radius: 0;
    max-height: 100% !important;
  }
  .modal-body {
    padding: 0;
  }
  .modal-footer {
    padding: 0.5rem 0;
  }
  .modal-header {
    padding: 0 !important;
  }
  .modal-dialog-scrollable {
    max-height: 100% !important;
  }
}
::v-deep(.failed-get-location-modal-content) {
  width: 85%;
  margin: 0 auto;
  padding: 56px 0;
  border-radius: 8px !important;
}
::v-deep(.failed-get-location-modal-header) {
  position: relative;
  svg {
    position: absolute;
    top: -72px;
    right: -12px;
  }
}
::v-deep(.failed-get-location-modal-body) {
  text-align: center;
}
::v-deep(.failed-get-location-modal-footer) {
  padding: 0 !important;
}
::v-deep(.failed-get-location-modal-button) {
  width: 95%;
  min-height: 24px;
  padding: 1.5vh;
  margin: 8px 0;
  border-radius: 5px;
  border: none;
  background-color: #12b6d4;
  color: white;
}
::v-deep(.failed-get-location-modal-close-button) {
  width: 95%;
  min-height: 24px;
  padding: 1.5vh;
  margin: 8px 0;
  border-radius: 5px;
  border: none;
  background-color: #c6c6c6;
  color: white;
}
.keyword-input {
  flex-grow: 1;
  margin: 12px 0;
  border-radius: 8px;
  border: solid 1px lightgray;
  padding: 12px 8px;
  font-size: 14px;
}

/* user guideline modal */
.overwrite-modal-body {
  color: black;
  .app-user-user-guideline-contents {
    .user-guideline-title {
      padding: 12px 20px 0 20px;
      box-shadow: none;
    }
  }
}
.user-guideline-modal-footer {
  width: 100%;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  button {
    width: 100px;
  }
}
</style>
