<template>
  <div class="select-wrap" :style="{ width: width }">
    <select v-model="selectedValue" :style="{ 'paddingLeft': valueOffsetLeft }">
      <optgroup
        v-for="(group, groupIndex) in options"
        :key="`${idPrefix}-option-${groupIndex}`"
        :label="group.label"
      >
        <option
          v-for="(option, index) in group.options"
          :key="`${idPrefix}-option-${index}`"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
export default {
  name: 'AppUserSelector',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    idPrefix: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '120px'
    },
    valueOffsetLeft: {
      type: String,
      default: '10px'
    },
  },
  data() {
    return {
      selectedValue: this.value
    }
  },
  watch: {
    selectedValue() {
      this.$emit('select-chaged', this.selectedValue)
    }
  },
}
</script>

<style lang="scss" scoped>

.select-wrap {
  position: relative;
  select {
    position: relative;
    padding: 10px;
    border: 1px solid #C6C6C6;
    border-radius: 4px;
    width: 100%;
    background-color: white;
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.select-wrap:after {
  content: "";
  position: absolute;
  right: 14px;
  top: 46%;
  width: 8px;
  height: 8px;
  border-top: 2px solid #12B6D4;
  border-left: 2px solid #12B6D4;
  transform: translateY(-50%) rotate(-135deg);
  font-size: 20px;
  pointer-events: none;
}
</style>
