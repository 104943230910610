<template>
  <b-modal
    ref="add-place-preview"
    modal-class="common-modal"
    dialog-class="common-modal-dialog"
    content-class="review-modal-content"
    header-class="review-modal-header"
    body-class="common-modal-body"
    hide-header-close
    hide-footer
    hide-backdrop
    scrollable
    no-fade
  >
    <AddSpacePreview
      v-if="placeType === 'スペース'"
      :placeType="placeType"
      :value="value"
      @hide-modal-click="hideModal"
      @post-space="$_onPostSpace"
    />
    <AddEventPreview
      v-else-if="placeType === 'イベント'"
      :placeType="placeType"
      :value="value"
      @hide-modal-click="hideModal"
      @post-event="$_onPostEvent"
    />
    <template #modal-header>
      <Header
        @left-arrow-click="hideModal"
        @logo-click="hideModal"
      />
    </template>
  </b-modal>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import AddSpacePreview from '@/components/organisms/user/modals/contents/AppUserAddSpacePreview'
import AddEventPreview from '@/components/organisms/user/modals/contents/AppUserAddEventPreview'
import { postSpace } from '@/helper/firestore/space'
import { postEvent } from '@/helper/firestore/event'
import { showInfoPopupAlert } from '@/helper/common'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserAddPlacePreviewModal',
  components: {
    Header,
    AddSpacePreview,
    AddEventPreview 
  },
  props: {
    placeType: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    userType: {
      type: String,
      default: ''
    },
    review: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    selectedPlace() {
      return this.$store.getters['place/selectedPlace']
    },
  },
  methods: {
    showModal() {
      this.$refs['add-place-preview'].show()
    },
    hideModal() {
      this.$refs['add-place-preview'].hide()
    },
    async $_onPostSpace(value) {
      this.$store.dispatch('loadingMask/showLoadingMask')

      try {
        const rs = await postSpace({
          public: '非公開',
          addedBy: 'user',
          spaceName: this.value.name,
          embeddedStreetView: '',
          twitterKeywords: '',
          twitterKeywordUrl: '',
          zip: this.value.zipcode,
          fullAddress: `${this.value.prefecture}${this.value.area}${this.value.address}`,
          prefecture: this.value.prefecture,
          area: this.value.area,
          address: this.value.address,
          applyUrl: this.value.applyPageUrl || '', // 任意のため、undefinedになりうる
          coordinates: this.value.coordinates,
          remarks: this.value.remarks || '', // 任意のため、undefinedになりうる
          type: 'space',
          likes: 0,
          review: 0
        })
        // console.log(rs)
        // ローディングマスクを閉じる
        this.$store.dispatch('loadingMask/hideLoadingMask')
        this.hideModal()
        this.$emit('go-back')
        // トースト表示(ローディングマスクの非表示より先に表示されるので、タイムアウト設定)
        setTimeout(() => this.$_showResultToast(), 500)
      } catch(error) {
        this.$store.dispatch('loadingMask/hideLoadingMask')
        window.alert(`クチコミ投稿に失敗しました。${error}`)
      }
    },
    async $_onPostEvent(value) {
      this.$store.dispatch('loadingMask/showLoadingMask')

      try {
        // console.log(this.value)
        const rs = await postEvent({
        // console.log({
          public: '非公開',
          addedBy: 'user',
          eventName: this.value.name,
          spaceName: this.value.spaceName,
          zip: this.value.zipcode,
          fullAddress: `${this.value.prefecture}${this.value.area}${this.value.address}`,
          prefecture: this.value.prefecture,
          area: this.value.area,
          address: this.value.address,
          eventInfoUrl: this.value.applyPageUrl || '', // 任意のため、undefinedになりうる
          coordinates: this.value.coordinates,
          remarks: this.value.remarks || '', // 任意のため、undefinedになりうる
          type: 'event',
          likes: 0,
          copyRightUrl: '',
          endOfPublicationDate: `${this.value.endOfPublicationDateYear}/${this.value.endOfPublicationDateMonth}/${this.value.endOfPublicationDateDay}`,
          heldDates: this.value.heldDate.map(d => `"${d}"`).join(","),
          termBeginDate: (this.value.termBeginDateYear && this.value.termBeginDateMonth && this.value.termBeginDateDay)
            ? `${this.value.termBeginDateYear}/${this.value.termBeginDateMonth}/${this.value.termBeginDateDay}`
            : null,
          termEndDate: (this.value.termEndDateYear && this.value.termEndDateMonth && this.value.termEndDateDay)
            ? `${this.value.termEndDateYear}/${this.value.termEndDateMonth}/${this.value.termEndDateDay}`
            : null,
          exteriorImage: '',
          eventImageName: '',
          companyName: this.value.companyName,
          email: this.value.email,
          responsiblePersonName: this.value.responsiblePersonName,
          tell: this.value.tell,
        })
        // console.log(rs)
        // ローディングマスクを閉じる
        this.$store.dispatch('loadingMask/hideLoadingMask')
        this.hideModal()
        this.$emit('go-back')
        // トースト表示(ローディングマスクの非表示より先に表示されるので、タイムアウト設定)
        setTimeout(() => this.$_showResultToast(), 500)
      } catch(error) {
        this.$store.dispatch('loadingMask/hideLoadingMask')
        window.alert(`クチコミ投稿に失敗しました。${error}`)
      }
    },
    $_showResultToast() {
      const html = this.placeType === 'スペース'
        ? `
          <img src="${require('@/assets/image/add-place/icon_add_place_result.svg')}" />
          <p style="font-size: 1rem; margin: 32px 0 8px 0;">情報のご提供ありがとうございます。</p>
          <p style="font-size: 1rem; margin-bottom: 8px;">内容を確認し、近日中に</p>
          <p style="font-size: 1rem;">Poshspaceに掲載させていただきます。</p>
          <p style="font-size: 12px; color: #A5A5A5; margin: 0 16px 0 16px; text-align: left;">※掲載の可否は通知されませんので、あらかじめご了承ください。</p>
        `
        : `
          <img src="${require('@/assets/image/add-place/icon_add_place_result.svg')}" />
          <p style="font-size: 14px; margin: 32px 0 8px 0;">情報のご提供ありがとうございます。</p>
          <p style="font-size: 14px; margin-bottom: 8px;">また、Poshspaceに掲載される</p>
          <p style="font-size: 14px; margin-bottom: 8px;">イベント告知用の画像のご用意がある場合は</p>
          <p style="font-size: 14px; margin-bottom: 8px;"><a href="mailto:poshmap01@gmail.com">poshmap01@gmail.com</a> 宛に</p>
          <p style="font-size: 14px; margin-bottom: 8px;">画像を添付の上、送信してください。</p>
          <p style="font-size: 14px; margin-bottom: 8px;">内容を確認し、近日中に</p>
          <p style="font-size: 14px;">Poshspaceに掲載させていただきます。</p>
          <p style="font-size: 12px; color: #A5A5A5; margin: 0 16px 0 16px; text-align: left;">※掲載の可否は通知されませんので、あらかじめご了承ください。</p>
        `
      showInfoPopupAlert(
        '',
        false,
        {
          html,
          customClass: {
            popup: 'review-action-result',
            actions: 'custom-actions'
          }
        }
      )
    }
  }
}
</script>
  
<style lang="scss">
.review-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.review-modal-header {
  flex-direction: column;
  align-items: center;
}
.custom-actions {
  flex-direction: column;
  width: 100% !important;
  button {
    width: 80%;
  }
}
.review-action-result {
  padding: 48px 0 !important;
}
</style>